import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import {getCookie} from '../lib/common'
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import $ from "jquery";
const kakao = window.kakao;
function Contact() {
    const { register, handleSubmit ,formState: { errors } } = useForm();
    const [enMode,setEnMode]= useState(false); 
    const onSubmit = (data) =>{


     const company = data.company;
     const email = data.email;
     const contents = data.contents;
     const dataString = 'company='+ company + '&email='+ email + '&contents=' + contents;

     $.ajax({
       type: "POST",
       url: "/contact_process.php",
       data: dataString,
       success: function(){
         alert(!enMode?'문의메일이 접수되었습니다':"Your email has been sent successfully.")
       }
     });
    
};
    useEffect(()=>{
        const lan = getCookie('lan');
        if(lan==='en'){
            setEnMode(true);
        }

		try{
        	var container = document.getElementById('map');
			var options = {
				center: new kakao.maps.LatLng(37.522645297306845, 126.99109715750781),
				level: 3
			};

			var map = new kakao.maps.Map(container, options);
        	var zoomControl = new kakao.maps.ZoomControl();
        	map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
        	// 마커가 표시될 위치입니다 
			var markerPosition  = new kakao.maps.LatLng(37.522645297306845, 126.99109715750781);
			
			// 마커를 생성합니다
			var marker = new kakao.maps.Marker({
			    position: markerPosition,
			   
			});
			
			// 마커가 지도 위에 표시되도록 설정합니다
			marker.setMap(map);
			map.setZoomable(false);  
		}catch(e){ console.log(e) }

    },[])

    return (
        <div className="contents_wrap">
            <div className="contact">
                <div className='ani_t_line'>
                    <div className="section_title">
                    CONTACT
                </div>
                </div>
                
                <div className='contact_map' id='map'>
                   
                </div>
                <div className='section_container'>
                    <div className="section_title">Address</div>
                    <div className="section_contents">
                        <div className='sc_title'><span className="t_kr">서울특별시 강남구 강남대로 122길 22, 2층<br/>
                        엔에스이엔엠
                        </span><span className='t_en'>
                        22, Gangnam-daero 122-gil, Gangnam-gu, Seoul, Republic of Korea / 06119
NSENM

                        </span>
                        </div>
                        <div className='sc_subtitle'>
                        <span className="t_kr">
                        52, Seobinggo-ro 51-gil, Yongsan-gu, Seoul, <br/>Republic of Korea / 04384</span>
                        </div>
                        <div className='sc_items'>
                            <div className='sc_item'>
                                <div className='sc_title'><span className="t_kr">대표 번호</span><span className='t_en'>Phone</span></div>
                                <div className='sc_contents'>02-6743-5600</div>
                            </div>
                            <div className='sc_item'>
                                <div className='sc_title'><span className="t_kr">협업 문의</span><span className='t_en'>
                                Business partnership / Collaboration inquires
                                    </span></div>
                                <div className='sc_contents'>
                                <span className="t_kr">
                                NSENM은 콘텐츠 제작부터 다양한 브랜드와의 협업 및 광고 홍보 사업등을 진행합니다.<br/>
                                비즈니스 관련 협업 문의는 아래 이메일 폼을 통해 문의 바랍니다.
                                </span><span className='t_en'>NSENM conducts contents production, collaboration with various brands, and advertisement promotion business. For business-related collaboration inquiries, please contact us through the email form below.</span>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='input_section'>
                                    <div className='input_item'>
                                        <input type={'text'} placeholder={!enMode?'이름(회사명)':'Name(Company)'} {...register("company", { required: !enMode?'회사명을 입력해주세요':'Enter your company name'})}/>
                                        <div className='error_msg'><ErrorMessage errors={errors} name="company" /></div>
                                    </div>
                                    <div className='input_item'>
                                        <input type={'text'} placeholder={!enMode?'이메일':'Email'}  {...register("email", { required: !enMode?'이메일을 입력해주세요':'Please input email' })}/>
                                        <div className='error_msg'><ErrorMessage errors={errors} name="email" /></div>
                                    </div>
                                    <div className='input_item'>
                                       <textarea placeholder={!enMode?'문의내용':'Contents'}  {...register("contents", { required: !enMode?'내용을 입력해주세요':'Please input contents' })}></textarea>
                                       <div className='error_msg'><ErrorMessage errors={errors} name="contents" /></div>
                                    </div>
                                </div>
                                <div className='bt_section'>
                                    <button className='default_bt submit_bt' onClick={handleSubmit(onSubmit)}><span className="t_kr">이메일 보내기</span><span className='t_en'>Send email</span></button>
                                </div>
                                </form>
                                </div>
                              
                            </div>

                        </div>
                    </div>
                    
                </div>
             
            </div>
        </div>

    )



}


export default Contact;
