import { useEffect, useState } from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from 'react-router-dom'; 
import {getCookie} from '../lib/common'
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);

function Footer() {
    const [enMode,setEnMode] = useState(false); 
    
    useEffect(()=>{
        const lan = getCookie('lan');
        if(lan==='en'){
            setEnMode(true);
            $('.footer').addClass('en_languege')
        }else{
            setEnMode(false);
            $('.footer').removeClass('en_languege')
        }

        const mouse_pointer = document.getElementById('mouse_pointer');
        window.addEventListener('mousemove',(e)=>{
            
            mouse_pointer.style.left=e.clientX-5+"px";
            mouse_pointer.style.top=e.clientY-5+"px";
        })
    },[])


    return(
        <div className="footer">
             <div id="mouse_pointer" className='mouse_pointer'></div>
            <div className="ft_left_section">
                <div className="ft_address">
                    <span className="t_kr">서울특별시 강남구 강남대로 122길 22, 2층<br/>엔에스이엔엠</span>
                    <span className="t_en">22, Gangnam-daero 122-gil, Gangnam-gu, Seoul, <br/>Republic of Korea / 06119 NSENM</span>
                </div>
                <div className="ft_relative">
					<span className="t_kr">관계사 : (주)아이오케이스튜디오, (주)아이오케이아카데미, (주)매시브이엔씨, 제이준코스메틱(주)</span>
				</div>
                <div className="ft_sub_menu">
					<a href="/policy"><span className="t_kr">내부정보관리규정</span><span className='t_en'>INTERNAL REGULATION</span></a>
					&nbsp;&nbsp;&nbsp;
					<a href="/ethics"><span className="t_kr">윤리강령</span><span className='t_en'>ETHICAL MANAGEMENT</span></a>
				</div>
                <div className="ft_copyright">©2021 NSENM</div>
            </div>
            <div className="ft_menu_section">
                <ul>
                    <li><a href="/about">ABOUT US</a></li>
                    <li><a href="/what">WHAT WE DO</a></li>
                    <li><a href="/artist">ARTIST</a></li>
                    <li><a href="/audition">AUDITION</a></li>
                  
                </ul>
            </div>
            <div className="ft_menu_section ft_sub_menu">
                <ul>
                   
                    <li><a href="/contact">CONTACT</a></li>
                    <li><a href="/announcement">IR</a></li>
                    <li><a href="/dart">DART 공시정보</a></li>
                </ul>
            </div>
            <div className="ft_right_section">
               
                <ul className="ft_sns_section">
                     <li><a href="https://www.facebook.com/IOKCOMPANY" rel="noreferrer" target="_blank">facebook</a></li>
                    <li><a href="https://www.instagram.com/iokcompany/" rel="noreferrer" target="_blank">instagram</a></li>
                    <li><a href="https://post.naver.com/my.naver?memberNo=37878409" rel="noreferrer" target="_blank">naver post</a></li>
                    <li><a href="https://www.youtube.com/channel/UCeybOdFGMm_RxjXA7iS2jBw" rel="noreferrer" target="_blank">youtube</a></li>
                   
                </ul>
            </div>
            <div className='ft_bottom_info_mobile'>
                 <div className="ft_address">
				 	<span className="t_kr">서울특별시 용산구 서빙고로51길 52 9층<br/>엔에스이엔엠</span>
                    <span className="t_en">52, Seobinggo-ro 51-gil, Yongsan-gu, Seoul, <br/>Republic of Korea / 04384 NSENM</span>
				</div>
                 <div className="ft_relative">
				 	<span className="t_kr">관계사 : (주)아이오케이스튜디오, (주)아이오케이아카데미, (주)매시브이엔씨, 제이준코스메틱(주)</span>
				</div>
                 <div className="ft_sub_menu">
				 	<a href="/policy"><span className="t_kr">내부정보관리규정</span><span className='t_en'>INTERNAL REGULATION</span></a>
					&nbsp;&nbsp;&nbsp;
					<a href="/ethics"><span className="t_kr">윤리강령</span><span className='t_en'>ETHICAL MANAGEMENT</span></a>
				</div>
                 <div className="ft_copyright">©2021 NSENM</div>
            </div>
            <div className="ft_top_bt" onClick={()=>{window.scrollTo(0,0)}}><img src="/img/ic_arrow_up.svg" alt="" /></div>

        </div>

    )



}

export default Footer;
